<template src="./summary.html"></template>
<style scoped src="./summary.css"></style>

<script>
import accountConstants from '@/constants/AccountConstants.vue'
import AccountService from '@/services/AccountService'
import Loading from 'vue-loading-overlay'
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import jsonToExcel from 'vue-json-excel'
import pagination from '@/components/vtable/vtpagination.vue'
import AppConstantsVue from '../../../constants/AppConstants.vue'

export default {
    data () {
        return {
            userSummary : {
               firstName : '',
               lastName : '',
               accbalance : '',
               accountStatus :'',
               subStatus :'',
               eidCheck :'',
               eidResult : '',
               eidSkipped : '',
               mrmsStatus : '',
               mobileStatus : '',
               eidStatus : '',
               emailStatus :'',
               deviceType : ''
            },
            accountStatus: accountConstants.accountStatus,
            eidStatusList: accountConstants.eidStatusList,
            emailStatusList: accountConstants.emailStatusList,
            mrmsStatusList: accountConstants.mrmsStatusList,
            mobileStatusList: accountConstants.mobileStatusList,
            deviceOrigin: accountConstants.deviceOrigin,
            eidCheck:  accountConstants.eidCheck,
            eidResult:accountConstants.eidResult,
            eidSkipped:accountConstants.eidSkipped,
            userssummary: [],
            loaderVisible : false, 
            temp : '',
            columns :['Email','FirstName','LastName','AccountStatus','AccountSubStatus','AccountBalance','SignUpDate', 'SignUpDeviceIP', 'SignUpDeviceOrigin','action'],
            options : {
                headings : {
                    action : 'Actions',
                    SignUpDeviceOrigin :'Device Origin',
                    SignUpDeviceIP: 'DeviceIP'
                },
            },
            Utils : Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            selectedItem: {},
            reasonNotes: '',
            fields: {
                'Email': 'Email',
                'First Name': 'FirstName',
                'Last Name': 'LastName',
                'Account Status': 'AccountStatus',
                'Account Sub Status': 'AccountSubStatus',
                'Account Balance': 'AccountBalance',
                'SignUp Date': 'SignUpDate',
                'Device Origin': 'SignUpDeviceOrigin'
            },
            enableLazyLoading: AppConstantsVue.enableLazyLoading,
        }
    },
    components : {
        Loading, modal, jsonToExcel, pagination
    },
    methods : {
        ...mapActions(['fetchAccountDetails']),
        async showDetails() {
            try {
                if(this.userSummary.accbalance !== "") {
                    this.userSummary.accbalance=parseFloat(this.userSummary.accbalance).toFixed(2);
                }
                this.loaderVisible = true
                await AccountService.methods.FetchUserSummary(this.userSummary)
                .then( res => {
                    this.userssummary = res.csGetUsersSummaryResponse.UsersSummaryData ? res.csGetUsersSummaryResponse.UsersSummaryData.UserSummaryData : null
                    if(this.userssummary) {
                        this.userssummary = Array.isArray(this.userssummary) ? this.userssummary : [this.userssummary]
                    } else{
                        this.userssummary = []
                    }
                    if (this.enableLazyLoading) {
                        this.$refs.paginationRef.selectedPage = 1
                    }
                })
                .catch( err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false               
            }
        },
        checkAccountBalance() {
            this.userSummary.accbalance = this.userSummary.accbalance < 0 ? '' : this.userSummary.accbalance
        },
        removeUnderscoreText(data) {
            if(data) {
               data = Array.isArray(data) ? data : [data]
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text
                    })
                }
                data.map(reciptient)
            }else{
                data = []
            }   
            return data
        },
        mapConstants(userSummary) {
            let reducer = (accumulator, currentValue) => {
                return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
            }
            userSummary.forEach(item => {
                item.AccountStatus = accountConstants.accountStatus.reduce(reducer,item.AccountStatus)
                item.AccountSubStatus = accountConstants.accountSubStatus.reduce(reducer,item.AccountSubStatus)
                item.SignUpDeviceOrigin = accountConstants.deviceOrigin.reduce(reducer,item.SignUpDeviceOrigin)
                item.AccountBalance = (parseFloat(item.AccountBalance || '00')).toFixed(2)
            })
            return userSummary
        },
        accountAction(row,action){
            this.$router.push({
                name  : action,
                params: {
                    fromName: this.$route.name,
                    email : row.Email,
                    userSummaryFilterParams: {
                        userSummary : this.userSummary
                    }
                }
            })
        },
        openNotesModal(data) {
            this.selectedItem = data
            this.$bvModal.show('modal-center')
        },
        async closeAccount() {
            if(this.reasonNotes.trim().length > 0) {
                this.loaderVisible = true
                if (this.selectedItem.Email && this.selectedItem.Email.length > 0) {
                    await this.fetchAccountDetails(this.selectedItem.Email)
                    let user = await this.getAccountDetails
                    user.DateOfBirth = user.DateOfBirth === '' ? null : new Date(user.DateOfBirth)
                    user.skipEqufax = user.EIDSkipped === "true" ? "Yes" : "No"
                    user.SkipEquifaxVerification = false
                    user.NewMRMSSkipped = false
                    user.AccountStatus = "2" // account status closed
                    await AccountService.methods.UpdateUserProfile(user)
                    .then(() => {
                        this.showAlert(1, 'Profile Updated Successfully')
                        AccountService.methods.UpdateUserProfileNotes(this.reasonNotes,this.selectedItem.Email)
                        .then( ()=> {})
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                    }).catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                } else {
                    this.showAlert(null, 'Unable to retrieve user details')
                }
                this.selectedItem = {}  
                this.reasonNotes = ''
                this.loaderVisible = false 
            } else {
                this.selectedItem = {}
                this.loaderVisible = false
                this.showAlert(null, 'Please Enter Notes')
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        exportTableData() {
            if(this.userssummary.length > 0) {
                return this.mapConstants(this.removeUnderscoreText(JSON.parse(JSON.stringify(this.userssummary))))
            }
        },
    },
    computed :{
        ...mapGetters(['getAccountDetails', 'getNonZeroAccountuser', 'getReviewRequiredUser', 'getSignedUser']),
        userssummaryTable() {
            let userSummaryData = []
            if (this.userssummary) {
                userSummaryData = JSON.parse(JSON.stringify(this.userssummary))
                if (this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage ?? 1
                    userSummaryData = (JSON.parse(JSON.stringify(this.userssummary))).slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                userSummaryData = this.mapConstants(this.removeUnderscoreText(userSummaryData))
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.userssummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return userSummaryData
        }
    },
    async mounted () {
        this.userSummary = this.$route.params.userSummaryFilterParams?.userSummary || 
        {
            firstName : '',
            lastName : '',
            accbalance : '',
            accountStatus :'',
            subStatus :'',
            eidCheck :'',
            eidResult : '',
            eidSkipped : '',
            mrmsStatus : '',
            mobileStatus : '',
            eidStatus : '',
            emailStatus :'',
            deviceType : ''
        }
        this.$route.params.userSummaryFilterParams?.userSummary ? this.showDetails() : ''
        if(this.$route.params.from === 'dashboardWidget') {
            switch(this.$route.params.type) {
                case 'NZB': {
                    this.userssummary = (this.getNonZeroAccountuser).UsersSummaryData.UserSummaryData ?? null
                    this.userSummary.accbalance = '0.00'
                    break
                }
                case 'RR' : {
                    this.userssummary = this.getReviewRequiredUser
                    this.userSummary.eidStatus = 2
                    this.userSummary.mrmsStatus = 2
                    break
                }
                case 'SU' : {
                    this.userssummary = (this.getSignedUser).UsersSummaryData.UserSummaryData ?? null
                    this.userSummary.accountStatus = 4
                    break
                }
            }
        }
    }
}
</script>